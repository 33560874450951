
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButtons, IonButton, menuController, modalController } from "@ionic/vue";
import { refresh, menu } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { Calendar } from "v-calendar";
import moment from "moment";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";
import apiTickets from "@/services/tickets";

import TicketDetail from "@/components/ticket/TicketDetail.vue";

export default {
    name: "Tickets",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButtons,
        IonButton,
        Calendar,
    },
    setup() {
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;

        const loading = ref(false);

        const tickets = ref([]);
        const openedTickets = ref([]);
        const closedTickets = ref([]);
        const ticketsNotAssigned = ref([]);

        const showAssegnati = ref(true);
        const showLiberi = ref(false);

        const date = ref(new Date());
        const selectedDay = ref(null); // Add state to store selected day

        const currentTicketsToShow = ref([]); //ticket to filter clicked tickets

        const setAttributes = computed(() => {
            return openedTickets.value.map((ticket) => ({
                key: `ticket.${ticket.tickets_id}`,
                highlight: {
                    color: "blue",
                    fillMode: "solid",
                },
                dates: ticket.tickets_assign_date,
                customData: ticket,
            }));
        });

        /**
         * Filter my tickets based on selected day
         */
        let selectedDate = null;
        const dayClicked = (day) => {
            selectedDate = day.id;
            selectedDay.value = day;
            const selectedTickets = day.attributes;

            if (selectedTickets) {
                //svuoto e popolo con ticket della giornata selezionata
                currentTicketsToShow.value = [];
                selectedTickets.forEach((element) => {
                    currentTicketsToShow.value.push(element.customData);
                });
            } else {
                currentTicketsToShow.value = [];
            }
            //currentTicketsToShow.value = [...selectedTickets];
        };

        /**
         * Open sidebar menu
         */
        const openMenu = () => {
            menuController.open("app-menu");
        };

        function setActiveTickets(type) {
            if (type === "liberi") {
                showLiberi.value = true;
                showAssegnati.value = false;
            } else {
                showAssegnati.value = true;
                showLiberi.value = false;
            }
        }

        async function openDetail(ticket) {
            //console.log(richiestaDetail);
            const modal = await modalController.create({
                component: TicketDetail,
                componentProps: {
                    data: ticket,
                },
            });
            modal.onDidDismiss().then((detail) => {
                /* if (detail.data != undefined) {
                richieste.value = [detail.data, ...richieste.value];
                openToast("Nuova richiesta inserita", "toast_success");
                } */
            });
            return modal.present();
        }

        /**
         * Load tickets assigned to me and closed
         */
        async function loadTickets() {
            loading.value = true;
            try {
                // Tutti i ticket
                tickets.value = await apiTickets.getTickets(userID);
                // Ticket aperti
                openedTickets.value = tickets.value.filter((ticket) => ticket.tickets_status != 5);

                // Ticket da mostrare (tutti quelli aperti o giornata selezionata)
                const today = new Date().toISOString().split("T")[0];
                currentTicketsToShow.value = openedTickets.value.filter((ticket) => {
                    if (!ticket.tickets_assign_date) return false;
                    // Estrai la parte 'YYYY-MM-DD' dalla data di assegnazione
                    const assignDate = ticket.tickets_assign_date.split(" ")[0];
                    return assignDate === today;
                });

                // Ticket chiusi
                closedTickets.value = tickets.value.filter((ticket) => ticket.tickets_status == 5).slice(0, 200);
                // Ticket non assegnati
                ticketsNotAssigned.value = await apiTickets.getFreeTickets();
            } catch (error) {
                console.error(error);
                openToast("Errore durante la richiesta dei tickets", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        /**
         * Set correct background for data assegnazione if expired
         */
        const expiredTicket = computed(() => {
            return (scadenza) => {
                let className = "not_epixred";
                if (scadenza) {
                    const dataScadenza = moment(scadenza).valueOf();
                    const dataAttuale = moment().valueOf();

                    if (dataScadenza < dataAttuale) {
                        className = "expired_ticket";
                    } else {
                        className = "not_epixred";
                    }
                    return className;
                } else {
                    return className;
                }
            };
        });

        onMounted(() => {
            //GET TICKETS DATA
            loadTickets();
        });

        return {
            loading,
            expiredTicket,
            dateFormat,
            refresh,
            openDetail,
            //Nuovi campi
            loadTickets,
            openMenu,
            menu,
            tickets,
            openedTickets,
            closedTickets,
            ticketsNotAssigned,
            showLiberi,
            showAssegnati,
            setActiveTickets,
            //Calendar
            date,
            selectedDay,
            setAttributes,
            dayClicked,
            //filter ticket based on calendr click
            currentTicketsToShow,
        };
    },
};
